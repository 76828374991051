import actions from './actions';

const initialState = {
  loading: false,
  formLoading: false,
  formSubmitting: false,
  messageList: null,
  messageLoading: false,
  messageSubmitting: false,
  lastCreatedMessage: null,

  allMessageList: [],
  allMessageLoading: false,
  allMessageCurrentPage: 1,
  allMessageTotalPages: 0,
  allMessagesTotalResults: 0,
  allMessageLimit: 10,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    case actions.UPDATE_LAST_CREATED_MESSAGE: {
      const newMessageList = Array.isArray(state.messageList)
        ? [...state.messageList]
        : [];
      newMessageList.push(action.payload);
      return {
        ...state,
        messageList: newMessageList,
        lastCreatedMessage: action.payload,
      };
    }

    case actions.SET_ADD_ALL_MESSAGE_LIST: {
      if (state.allMessageCurrentPage === action.payload.allMessageCurrentPage)
        return { ...state };
      const { allMessageList } = state;
      allMessageList.push(...action.payload.allMessageList);

      return { ...state, ...action.payload, allMessageList };
    }

    default:
      return state;
  }
}
