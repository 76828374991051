import React, { useEffect, useState } from 'react';
import Loader from '../Loader';

const Avatar = ({
  className,
  avatarUrl,
  name = '',
  size = 32,
  ...otherProps
}) => {
  const sharedProps = {
    className,
    size,
    'data-testid': name ? `avatar:${name}` : 'avatar',
    ...otherProps,
  };

  const [avatarImage, setImageAvatar] = useState();

  useEffect(() => {
    if (avatarUrl) setImageAvatar(avatarUrl);
  }, [avatarUrl]);
  if (avatarUrl) {
    return avatarImage ? (
      <div
        className="d-inline-block"
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: '5px',
        }}
        {...sharedProps}
      >
        <img
          src={avatarImage}
          alt="Avatar"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '5px',
          }}
        />
      </div>
    ) : (
      <Loader minHeight="80px" maxHeight="80px" />
    );
  }

  return (
    <div
      className="d-inline-block"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: getColorFromName(name),
        color: '#fff',
        fontSize: `${Math.round(size / 1.7)}px`,
        textTransform: 'uppercase',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
      }}
      {...sharedProps}
    >
      <span>{name.charAt(0)}</span>
    </div>
  );
};

const colors = [
  '#DA7657',
  '#6ADA57',
  '#5784DA',
  '#AA57DA',
  '#DA5757',
  '#DA5792',
  '#57DACA',
  '#57A5DA',
];

const getColorFromName = (name) =>
  colors[name.toLocaleLowerCase().charCodeAt(0) % colors.length];

export default Avatar;
