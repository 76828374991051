import actions from './actions';

const initialState = {
  loading: false,
  formLoading: false,
  formSubmitting: false,
  interviewListByCandidate: [],

  interviewList: [],
  interviewListCurrentPage: 1,
  interviewListTotalPages: 0,
  interviewListTotalResults: 0,
  interviewListLimit: 10,
  interviewListLoading: false,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    case actions.SET_ADD_ALL_INTERVIEW_LIST: {
      if (
        state.interviewListCurrentPage ===
        action.payload.interviewListCurrentPage
      )
        return { ...state };
      const { interviewList } = state;
      interviewList.push(...action.payload.interviewList);

      return { ...state, ...action.payload, interviewList };
    }
    default:
      return state;
  }
}
