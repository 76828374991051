import { ConnectedRouter } from 'connected-react-router';
import { history } from 'helpers/history';
import React, { lazy, Suspense } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import IndexLayout from 'layouts';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import { routes } from 'helpers/routes';
import { AuthRoute, OrgPrivateRoute, PrivateRoute } from 'helpers/PrivateRoute';

const authRoutes = [
  {
    exact: true,
    path: routes.LOGIN,
    component: lazy(() => import('pages/auth/login')),
  },
  {
    exact: true,
    path: routes.RESET_PASSWORD,
    component: lazy(() => import('pages/auth/reset-password')),
  },
  {
    exact: true,
    path: `${routes.RESET_PASSWORD}/success`,
    component: lazy(() => import('pages/auth/reset-password/success')),
  },
  {
    exact: true,
    path: `${routes.RESET_PASSWORD}/invalid-token`,
    component: lazy(() => import('pages/auth/reset-password/invalid-token')),
  },
  {
    exact: true,
    path: routes.FORGOT_PASSWORD,
    component: lazy(() => import('pages/auth/forgot-password')),
  },

  {
    exact: true,
    path: `${routes.FORGOT_PASSWORD}/sent`,
    component: lazy(() => import('pages/auth/forgot-password/sent')),
  },
  {
    exact: true,
    path: routes.SIGNUP,
    component: lazy(() => import('pages/auth/register')),
  },
  {
    exact: true,
    path: routes.SIGNUP_CREATE,
    component: lazy(() => import('pages/auth/register-create')),
  },
  {
    exact: true,
    path: routes.ORGANIZATION_EXISTS,
    component: lazy(() => import('pages/auth/organisation-exists')),
  },
  {
    exact: true,
    path: `${routes.VERIFY_EMAIL}/invalid-token`,
    component: lazy(() => import('pages/auth/verify-email/invalid-token')),
  },
  {
    exact: true,
    path: `${routes.VERIFY_EMAIL}/success`,
    component: lazy(() => import('pages/auth/verify-email/success')),
  },
  {
    exact: true,
    path: routes.VERIFY_EMAIL,
    component: lazy(() => import('pages/auth/verify-email')),
  },
  {
    exact: true,
    path: routes.VERIFY_YOUR_EMAIL,
    component: lazy(() => import('pages/auth/verify-your-email')),
  },
  {
    exact: true,
    path: routes.VERIFY_LOGIN_EMAIL,
    component: lazy(() => import('pages/auth/login-verify')),
  },
  {
    exact: true,
    path: routes.ORGANIZATION_NEW,
    component: lazy(() => import('pages/organization/new')),
  },
];

const Router = () => {
  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={<Loader minHeight="100vh" />}>
        <IndexLayout>
          <Switch>
            {authRoutes.map((route) => (
              <AuthRoute
                key={route.path}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))}
            <Route
              exact
              component={lazy(() => import('pages/login-sync'))}
              path="/login-sync"
            />
            <Route
              exact
              path={routes.SIGNUP_WITH_INVITE}
              component={lazy(() => import('pages/auth/registerWithInvite'))}
            />
            <Route
              exact
              component={lazy(() => import('pages/portalJobs'))}
              path="/portals/:orgSlug"
            />
            <Route
              exact
              component={lazy(() => import('pages/portalJob'))}
              path="/portals/:orgSlug/jobs/:jobSlug"
            />
            <Route
              exact
              component={lazy(() => import('pages/portalJobApply'))}
              path="/portals/:orgSlug/jobs/:jobSlug/apply"
            />
            <PrivateRoute
              exact
              component={lazy(() => import('pages/domain/create'))}
              path={routes.DOMAIN_CREATE}
            />
            <PrivateRoute
              exact
              component={lazy(() => import('pages/logout'))}
              path={routes.SIGNOUT}
            />
            <PrivateRoute
              exact
              component={React.lazy(() => import('pages/dashboard'))}
              path="/dashboard"
            />

            <PrivateRoute
              exact
              component={React.lazy(() => import('pages/search'))}
              path={routes.SEARCH}
            />
            <PrivateRoute
              component={lazy(() => import('pages/jobId'))}
              path="/jobs/:jobId"
            />
            <PrivateRoute
              component={lazy(() => import('pages/account/index'))}
              path="/account"
            />

            {/* Notification Page hidden */}
            {/* <PrivateRoute
              exact
              component={lazy(() => import('pages/notification'))}
              path="/notification"
            /> */}
            <OrgPrivateRoute
              exact
              component={lazy(() => import('pages/organization/manage'))}
              path="/organization/manage"
            />
            <OrgPrivateRoute
              exact
              component={lazy(() => import('pages/organization/new'))}
              path="/organization/new"
            />

            <PrivateRoute
              exact
              component={lazy(() => import('pages/reviews'))}
              path="/reviews"
            />

            <PrivateRoute
              component={lazy(() => import('pages/talent-community'))}
              path="/talent-community"
            />
            <PrivateRoute
              component={lazy(() => import('pages/allApplicants'))}
              path="/all-applicants"
            />
            <PrivateRoute
              component={lazy(() => import('pages/allInterviews'))}
              path={routes.ALL_INTERVIEWS}
            />

            <PrivateRoute
              component={lazy(() => import('pages/allRecordings'))}
              path={routes.ALL_RECORDINGS}
            />
            <PrivateRoute
              component={lazy(() => import('pages/allMessages'))}
              path="/all-messages"
            />

            <PrivateRoute
              exact
              component={lazy(() => import('pages/applicantReviews'))}
              path="/applicant-reviews/jobs/:jobId/applicants/:applicantId"
            />

            <Redirect from="/organization" to="/organization/manage" />
            <Redirect from="/" to="/dashboard" />
            <Redirect from="/job" to="/dashboard" />
          </Switch>
        </IndexLayout>
      </Suspense>
    </ConnectedRouter>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    userRoles: user.roles,
  };
};
export default connect(mapStateToProps)(Router);
