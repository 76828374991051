import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

const CreateJobPostModal = ({
  modalVisible = false,
  setModalVisible = () => {},
  onSubmit = () => [],
  onWorkflowSearch = () => {},
  workflowList = [],
  createWorkflow = () => {},
}) => {
  const [searchWorkflow, setSearchWorkflow] = useState('');
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  const handleSearch = (value) => {
    setSearchWorkflow(value);
    onWorkflowSearch(value);
  };

  const handleSubmit = (values) => {
    const submissionValues = { ...values };
    if (selectedWorkflow) submissionValues.workflow = selectedWorkflow;
    onSubmit(submissionValues);
  };

  const handleCreateWorkflow = async (value) => {
    createWorkflow({ name: value });
    setSelectedWorkflow(value);
  };

  useEffect(() => {
    const lastUpdatedWorkflow = workflowList[workflowList.length - 1]?.id;
    if (lastUpdatedWorkflow) {
      setSelectedWorkflow(lastUpdatedWorkflow);
    }
  }, [workflowList]);

  const debouncedCreateWorkflow = useCallback(
    debounce(handleCreateWorkflow, 300),
    []
  );

  const handleSelect = (value) => {
    if (value === 'create_new') {
      debouncedCreateWorkflow(searchWorkflow);
    } else {
      setSelectedWorkflow(value);
    }
  };

  const exactMatch = workflowList.some(
    (t) => t.name.toLowerCase() === searchWorkflow.toLowerCase()
  );

  return (
    <Modal
      title="Create new job"
      centered
      open={modalVisible}
      onOk={() => setModalVisible(false)}
      onCancel={() => setModalVisible(false)}
      closable={false}
      bodyStyle={{ padding: '5px 24px 24px 24px' }}
      footer={null}
      getContainer={document.getElementById('root')}
    >
      <Form layout="vertical" onFinish={(values) => handleSubmit(values)}>
        <p className="mb-3">
          Enter the name of the job position you&apos;re hiring for. Once
          created, you&apos;ll be taken to its setup screen where you can
          configure it.
        </p>
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: 'Job title is required',
            },
          ]}
        >
          <Input autoFocus placeholder="Job title" />
        </Form.Item>
        <Form.Item
          name="workflow"
          rules={[
            {
              required: true,
              message: 'Workflow is required',
            },
          ]}
        >
          <Select
            className="w-100"
            showSearch
            placeholder="Select a workflow"
            optionFilterProp="children"
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleSelect}
            value={selectedWorkflow}
            required
          >
            {workflowList
              .filter((t) =>
                t.name.toLowerCase().includes(searchWorkflow.toLowerCase())
              )
              .map((t) => (
                <Select.Option key={t.id} value={t.id}>
                  {t.name || ''}
                </Select.Option>
              ))}
            {!exactMatch && searchWorkflow && (
              <Select.Option key="create_new" value="create_new">
                Create new workflow: &quot;{searchWorkflow}&quot;
              </Select.Option>
            )}
          </Select>
        </Form.Item>
        <Button type="primary" className="me-3" htmlType="submit">
          Publish job
        </Button>
        <Button type="button" onClick={() => setModalVisible(false)}>
          Cancel
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateJobPostModal;
