import { Card, Select, Typography } from 'antd';
import React from 'react';

const MultiChoiceFilter = ({
  title = 'Title here',
  value,
  onChange = () => {},
  options = [],
  hasLowerCaseValue,
}) => (
  <Card className="border">
    <Typography.Title level={5}>{title}</Typography.Title>

    <Select
      className="w-100"
      onChange={(e) => onChange(e)}
      value={value}
      placeholder={`Select ${title}`}
    >
      {options.map((opt, index) => {
        const key = `option-${index}`;
        return (
          <Select.Option
            key={key}
            value={hasLowerCaseValue ? opt.toLowerCase() : opt}
          >
            {opt}
          </Select.Option>
        );
      })}
    </Select>
  </Card>
);

export default MultiChoiceFilter;
