import React from 'react';
import { Select } from 'antd';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

const LocationInput = ({ ...props }) => {
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

  const onSearch = (value) => {
    getPlacePredictions({ input: value });
  };

  return (
    <Select showSearch onSearch={onSearch} {...props} className="w-100">
      {!isPlacePredictionsLoading &&
        placePredictions.map((item) => (
          <Select.Option key={item.place_id} value={item.description}>
            {item.description}
          </Select.Option>
        ))}
    </Select>
  );
};

export default LocationInput;
