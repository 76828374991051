import { Button, Select, Modal, Typography } from 'antd';
import CreateJobPostModal from 'components/CreateJobPostModal';
import MainHeader from 'components/MainHeader';
import { JOB_STATUS_TYPE, SUBSCRIPTION_PLANS } from 'helpers/constants';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { useQuery } from 'hooks/useQuery';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from 'redux/jobs/actions';
import workflowActions from 'redux/workflows/actions';
import store from 'store';

const JobsLayout = ({
  children,
  createJob,
  lastCreatedJob,
  listWorkflows,
  workflowList,
  jobList,
  plan,
  createWorkflow,
}) => {
  const query = useQuery();

  const showUpgrade = plan === SUBSCRIPTION_PLANS.free && jobList.length >= 2;

  useEffect(() => {
    const isLoggedIn = store.get('token');
    if (isLoggedIn) listWorkflows();
  }, [listWorkflows]);

  const [modalVisible, setModalVisible] = useState(false);

  const debouncedSearch = debounce((searchTerm) => {
    listWorkflows({ searchTerm });
  }, 500);

  useEffect(() => {
    if (lastCreatedJob) {
      setModalVisible(false);
    }
  }, [lastCreatedJob]);

  return (
    <>
      <MainHeader />
      <div
        className="site-page-header-responsive bg-light border-top border-bottom d-flex justify-content-between align-items-center"
        style={{
          padding: '0 15px',
          width: '100%',
          height: '42px',
        }}
      >
        <Select
          value={query?.status || JOB_STATUS_TYPE.active}
          onChange={(val) => history.push(`${routes.DASHBOARD}?status=${val}`)}
          options={[
            {
              value: JOB_STATUS_TYPE.active,
              label: `View ${JOB_STATUS_TYPE.active} jobs`,
            },
            {
              value: JOB_STATUS_TYPE.inactive,
              label: `View hidden jobs`,
            },
            {
              value: JOB_STATUS_TYPE.archived,
              label: `View ${JOB_STATUS_TYPE.archived} jobs`,
            },
          ]}
        />

        {query?.status !== JOB_STATUS_TYPE.archived && (
          <Button
            key="1"
            type="button"
            onClick={() => {
              if (showUpgrade) {
                Modal.warning({
                  title: 'Upgrade Your Plan',
                  content: (
                    <div>
                      <Typography.Paragraph>
                        You have reached your limit of maximum active jobs in
                        your current plan.
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <a href={routes.ORGANIZATION_PLAN_BILLING}>
                          Upgrade your plan
                        </a>{' '}
                        to unlock more features.
                      </Typography.Paragraph>
                    </div>
                  ),
                  onOk() {},
                });
              } else setModalVisible(true);
            }}
          >
            <p className="text-black me-2 fw-bold">
              {showUpgrade && <i className="bi bi-lock me-2" />}Create new job
            </p>
          </Button>
        )}
        <CreateJobPostModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          onSubmit={createJob}
          onWorkflowSearch={(e) => debouncedSearch(e)}
          workflowList={workflowList}
          createWorkflow={createWorkflow}
        />
      </div>
      {children}
    </>
  );
};

const mapStateToProps = ({ workflows, jobs, user }) => {
  return {
    jobList: jobs.jobList,
    loading: jobs.loading,
    lastCreatedJob: jobs.lastCreatedJob,
    workflowList: workflows.workflowList,
    plan: user.organization?.plan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    listWorkflows: (payload) =>
      dispatch({
        type: workflowActions.INVOKE_LIST_WORKFLOWS,
        payload,
      }),
    createJob: (data) =>
      dispatch({
        type: actions.INVOKE_CREATE_JOB,
        payload: {
          data,
        },
      }),
    resetServerError: () =>
      dispatch({
        type: actions.SET_STATE,
        payload: { error: { isError: false } },
      }),
    createWorkflow: (payload) =>
      dispatch({
        type: workflowActions.INVOKE_CREATE_WORKFLOW,
        payload: { payload },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsLayout);
