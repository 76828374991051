import { Card, Input, Typography } from 'antd';
import React from 'react';

const TextFilter = ({
  onChange = () => {},
  title = 'Title here',
  type = 'text',
  value = null,
}) => (
  <Card className="border">
    <Typography.Title level={5}>{title}</Typography.Title>
    <Input
      type={type}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder={`Enter ${title}`}
    />
  </Card>
);

export default TextFilter;
