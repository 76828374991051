import { Card, Input, Space, Typography } from 'antd';
import CountryCurrencySelect from 'components/CountryCurrencySelect';
import React from 'react';

const CurrencyFilter = ({
  title = 'Title here',
  minValue = null,
  maxValue = null,
  onMinFilterChange = () => {},
  onMaxFilterChange = () => {},
  currencyCodeValue,
  onCurrencySelect = () => {},
}) => (
  <Card className="border">
    <Typography.Title level={5}>{title}</Typography.Title>

    <div className="d-flex gap-2">
      <CountryCurrencySelect
        searchable
        placeholder="Select"
        value={currencyCodeValue}
        onSelect={onCurrencySelect}
        className="w-25"
      />
      <Space direction="horizontal">
        <Input
          type="number"
          placeholder="Min"
          onChange={(e) => onMinFilterChange(e.target.value)}
          value={minValue}
        />
        <Input
          type="number"
          placeholder="Max"
          onChange={(e) => {
            if (!minValue) onMinFilterChange(0);
            onMaxFilterChange(e.target.value);
          }}
          value={maxValue}
        />
      </Space>
    </div>
  </Card>
);

export default CurrencyFilter;
